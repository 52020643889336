import React from 'react';
import { Card, Button } from 'antd';
import {DeleteTwoTone,} from '@ant-design/icons';
import './feedcard.css';

const FeedCard = ({data, deleteFunc}) => {
    console.log(deleteFunc);
    console.log(data)
    return (
        <Card className='FeedCard' bordered={true} 
        actions={[
            <DeleteTwoTone onClick={()=>{deleteFunc(data.FeedItemID)}} />
        ]}>
            <p>{data.Text}</p>           
        </Card>
    );
};
export default FeedCard;