import React, { useState } from 'react';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import './Cognito.css';


// replace these values with your own
const poolData = {
  UserPoolId: 'us-east-1_jh9GIYN5h',
  ClientId: '3rqmiqqos13ednvoc2sivme9r2'
};

const userPool = new CognitoUserPool(poolData);

function JPCognito({onSignIn}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [stage, setStage] = useState('signIn'); // add this
  
    const signUp = () => {
      const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: username })
      ];
  
      userPool.signUp(username, password, attributeList, null, (err, result) => {
        if (err) console.error(err);
        else {
          console.log(result);
          setStage('confirmSignUp');
        }
      });
    };
    const setSignUp = () => {
        setStage("signUp");
    };
  
    const confirmSignUp = () => {
      const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
  
      cognitoUser.confirmRegistration(confirmationCode, true, function(err, result) {
        if (err) console.error(err);
        else {
          console.log('Confirmation result: ' + result);
          setStage('signIn');
        }
      });
    };
  
    const signIn = () => {
      const authenticationDetails = new AuthenticationDetails({ Username: username, Password: password });
      const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
  
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => console.log(result),
        onFailure: (err) => console.error(err)
      });
      onSignIn();
    };
  
    const signOut = () => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) cognitoUser.signOut();
    };
  
    // Based on the stage, show different forms
    return (
      <div className='signupin'>
        {stage === 'signUp' && (
          <>
            <input onChange={e => setUsername(e.target.value)} placeholder="E-Mail Address" />
            <input onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
            <button onClick={signUp}>Sign Up</button>
          </>
        )}
        {stage === 'confirmSignUp' && (
          <>
            <input onChange={e => setConfirmationCode(e.target.value)} placeholder="Confirmation Code" />
            <button onClick={confirmSignUp}>Confirm Sign Up</button>
          </>
        )}
        {stage === 'signIn' && (
          <>
            <input onChange={e => setUsername(e.target.value)} placeholder="E-Mail Address" />
            <input onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
            <button onClick={signIn}>Sign In</button>
            <button onClick={setSignUp}>Sign Up</button>
          </>
        )}
      </div>
    );
  }
export default JPCognito;
