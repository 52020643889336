import React, { useEffect, useState, updateState } from 'react';
import { useParams } from "react-router-dom";
import { Button } from 'antd';
import { DeleteTwoTone, CloseCircleFilled, CheckCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import axios from 'axios';
import FeedCard from './feedcard';

function Feed() {
  const { feedID } = useParams();
  const [data, setData] = useState(null);
  const [confirmID, setConfirmID] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [newText, setNewText] = useState('');


  const handleNewTextChange = (e) => {
    setNewText(e.target.value);
    console.log(newText);
  };
  const openConfirm = (confirmID) => {
    setConfirmID(confirmID);
    setConfirmOpen(true);
  }

  const closeConfirm = () => {
    setConfirmOpen(false);
  }

  const handleConfirm = async () => {

    // handle the confirmation action here, e.g., delete an item

    const res = await axios({
      url: 'https://feedapi.jpmediadigital.com/feeditems?feedItemID=' + confirmID + '',
      method: 'delete',
    });


    closeConfirm();
    var removed = data.filter(function (item) {
      return item.FeedItemID !== confirmID;
    });
    setData(removed);
    // then close the dialog


  }

  const handleAdd = async () => {
    console.log(newText);
    var exp = new Date();

    const res = await axios({
      url: 'https://feedapi.jpmediadigital.com/feeditems',
      method: 'post',
      data: {
        feedID: feedID,
        feedItemText: newText,
        feedItemExpiration: (new Date(exp.setDate(exp.getDate() + 100))).getTime()

      }
    });

    setData([...data, JSON.parse(res.data.body)]);

    setNewText('');
    setShowAdd(false);


  };
  const closeAdd = () => {
    setShowAdd(false);
    setNewText('');

  };

  useEffect(() => {
    fetch('https://feedapi.jpmediadigital.com/feeditems/' + feedID)
      .then(res => res.json())
      .then(data1 => setData(JSON.parse(data1.body)))

  }, [feedID]);


  if (data) {
    return (
      <div>
        <h1 className="PageTitle">Feed Items</h1>
        <Button className="AddFeedItem" icon={<PlusCircleFilled className="addFeedItemIcon" />} onClick={() => { setShowAdd(true); }}><span className="afiText">Add Feed Item</span></Button>
        <div className="feedItems">
          {data.map(item => (

//(confirmID) => this.openConfirm(confirmID)

            <FeedCard data={item} deleteFunc={openConfirm} />
          ))}

          {data.length === 0 &&

            <div className="noFeedItems">
              <h4>No Feed Items</h4>
            </div>
          }
        </div>
        {confirmOpen && (
          <div className="modal" id="modal">
            <h2>Confirm</h2>
            <div className="modalcontent">Are you sure you want to delete?</div>
            <div className="actions">
              <Button icon={<CheckCircleFilled className='modalButtonYes' />} onClick={handleConfirm}>Yes</Button>
              <Button icon={<CloseCircleFilled className='modalButtonNo' />} onClick={closeConfirm}>No</Button>
            </div>
          </div>
        )}
        {showAdd && (
          <div className="modal" id="modal">
            <h2>Add New Feed Item</h2>
            <div className="newFeedcontent">
              <textarea className='newFeedItemText' name="newFeedItemText" id="newFeedItemText"
                onChange={handleNewTextChange}></textarea>
            </div>
            <div className="actions">
              <Button icon={<CheckCircleFilled className='modalButtonYes' />} onClick={handleAdd}>Add</Button>
              <Button icon={<CloseCircleFilled className='modalButtonNo' />} onClick={closeAdd}>Cancel</Button>
            </div>
          </div>
        )}

      </div>
    );
  }
  else {
    return <div>Loading</div>
  }
}
export default Feed;