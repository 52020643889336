import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import axios from 'axios';

function Feeds({username}){
    
    const [data, setData] = useState(null);
    const poolData = {
        UserPoolId: 'us-east-1_jh9GIYN5h',
        ClientId: '3rqmiqqos13ednvoc2sivme9r2'
      };
      const userPool = new CognitoUserPool(poolData);
    
    useEffect(() => {
      async function fetchData(){
        const res = await axios({
            url: 'https://feedapi.jpmediadigital.com/feeds/'+username,
            method:'get',
            Headers: {
                'Content-Type': 'application/json',
                'Authorization': ''
            }
            }, [username]);
        setData(JSON.parse(res.data.body));
        console.log(res.data);
          
        }

        fetchData();
    });
        
    //     fetch('https://feedapi.jpmediadigital.com/feeds/'+clientID)
    //         .then(res => res.json())            
    //         .then(data1 =>setData(JSON.parse(data1.body)))
    // }, [clientID]);


    if(data){
        
    return (
        
        <div>
            <h1 className="PageTitle">Feeds</h1>
            <h2>Please select a feed to view</h2>
            {data.map(item => (
                <div className='FeedLink' key={item.FeedID}>
                    <h3>
                    <a href={`/feed/${item.FeedID}`}>{item.FeedName}</a>
                    </h3>
                    </div>))}
        </div>
    );
            }
            else{
                return <div>Loading</div>
            }
}
export default Feeds;