import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import JPCognito from './Cognito';
import reportWebVitals from './reportWebVitals';
import { CognitoUserPool } from 'amazon-cognito-identity-js';


const poolData = {
  UserPoolId: 'us-east-1_jh9GIYN5h',
  ClientId: '3rqmiqqos13ednvoc2sivme9r2'
};

const userPool = new CognitoUserPool(poolData);

function Main() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) console.error(err);
        else {
          console.log('session validity: ' + session.isValid());
          setLoggedIn(session.isValid());
        }
      });
    }
  }, []);

  return (
    <div>
      {!loggedIn && <JPCognito onSignIn={()=>setLoggedIn(true)}/>}
      {loggedIn  && <App onSignOut={()=>{setLoggedIn(false)}} />}
    </div>
  )

}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
