import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './App.css';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { BrowserRouter as Router, Layout, Route, Routes, useParams } from 'react-router-dom';
import Feeds from './components/Feeds';
import Feed from './components/Feed';
import FeedCard from './components/feedcard';
import {UserPoolId,ClientId} from './utils/constants';

const poolData = {
  UserPoolId: UserPoolId,
  ClientId: ClientId
};
const userPool = new CognitoUserPool(poolData);
function App({ onSignOut }) {
//{userPool.getCurrentUser().getUsername()}
  const [clientID, setClientID] = useState('');
  const [username, setusername] = useState(userPool.getCurrentUser().getUsername());
  
  const signOut = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) cognitoUser.signOut();
    onSignOut();
  };
  useEffect(() => {
    fetch('https://feedapi.jpmediadigital.com/users/' + username)
    .then(res => res.json())
    .then(data1 =>setClientID(data1.body));
  }, [username]);

  function Home() {
    return <h1 className="PageTitle">Home</h1>;
  };

  return (
    <div className="App">
    <div className='header'>
      <div className="toolbar">
        <div className="left">
          JPMedia
        </div>
        <div className="right">
          <Button icon={<LogoutOutlined />} onClick={signOut} title="Logout" className='logout'>Logout</Button>
        </div>
      </div>
      <div className="navigation">
        <div className="navlist">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href={'/feeds'}>Feeds</a></li>
          </ul>
        </div>
      </div>
      </div>
      
     <div className="content">
      <Router>      
        <Routes>
        
          <Route exact path="/" element={<Home />} />
          <Route exact path="/feeds" element={<Feeds username={username} />} />
          <Route exact path="/feed/:feedID" element={<Feed />} />
        </Routes>
      </Router>
      </div>
    </div>
  );
}

export default App;
